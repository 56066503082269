import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import SubPageTitle from '../components/layout/SubPageTitle';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('custom-show');
        } else {
          entry.target.classList.remove('custom-show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.custom-hidden');
    const hiddenSlider = document.querySelectorAll('.custom__hidden-slider');
    hiddenElements.forEach((el) => observer.observe(el));
    hiddenSlider.forEach((el) => observer.observe(el));
  });

  return (
    <>
      <div
        className={`${pathname !== '/' ? 'bgr__sub-page' : 'bgr__home-page'}`}
      >
        <div className="h-full">
          <Header />
          {pathname === '/' ? <Banner /> : <SubPageTitle />}
        </div>
      </div>

      <Outlet />

      <Footer />
    </>
  );
};

export default Layout;
