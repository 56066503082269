import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MailForm = (data) => {
  const { register, handleSubmit } = useForm();

  const form = useRef();

  const onSubmit = () => {
    emailjs
      .sendForm(
        'service_2zm9jrm',
        'template_bn721r6',
        form.current,
        'INeiUFSaJ4Xeq3Y0W'
      )
      .then(
        (result) => {
          toast.success(
            'Contact Success! Please wait for the contact email from us',
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
          console.log(result.text);
        },
        (error) => {
          toast.error('Error! An error occurred. Please try again later', {
            position: toast.POSITION.TOP_CENTER
          });
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <form
        className={data?.classForm}
        ref={form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <input
            className={data?.classInput}
            type="text"
            {...register('name', { required: true })}
            placeholder="Your name *"
          />
        </div>

        <div>
          <input
            className={data?.classInput}
            type="email"
            name=""
            {...register('email', { required: true })}
            placeholder="Your email *"
          />
        </div>

        <div>
          <textarea
            className={data?.classTextarea}
            type="text"
            {...register('message', { required: true })}
            placeholder="Your message *"
          ></textarea>
        </div>

        <button className={data?.classBtn} type="submit">
          Send
        </button>
      </form>
    </>
  );
};

export default MailForm;
