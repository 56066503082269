import { useState } from 'react';
import ClientSlider from '../../components/home/ClientSlider';

import { useEffect, useRef } from 'react';
import AboutFirst from '../../assets/images/about-1.png';
import AboutSecond from '../../assets/images/about-2.png';
import HistoryAct from '../../assets/images/history-active.png';
import History from '../../assets/images/history.png';

const historyBz = [
  [
    {
      year: 2023,
      content: [
        'Da Lat Smart Farm Project',
        'Vietnam Food Safety Information System Development (4th Phase)',
        'Vietnam Smart City Certification Monitoring System Development',
        'Smart Video Calling Project (Japan)',
        'Offshore with Korean Security Solution Company (World-Class)'
      ]
    },
    {
      year: 2022,
      content: [
        'Vietnam Food Safety Information System Development (3rd  Phase)',
        'Enhancing financial transaction processing capacity for Core Banking System (Agribank)',
        'MK Live Implementation for Vi Sang Edu Vietnam'
      ]
    },
    {
      year: 2021,
      content: [
        'Vietnam Food Safety Information System Development (2nd  Phase)',
        'Online Opinion Analysis of Vietnamese on South Korea after Covid-19',
        'System Maintenance for Vi Sang Edu Vietnam'
      ]
    }
  ],
  [
    {
      year: 2020,
      content: [
        'eGov Consulting for Supreme People’s Court of Vietnam',
        'Vietnam real-time financial network (NAPAS) connection project Kookmin Bank'
      ]
    },
    {
      year: 2019,
      content: [
        'Consulting ISP for Supreme People’s Court of Vietnam',
        'Development eXCampus with tomato System Co., Ltd.',
        'Outsourcing with RSN Co., Ltd.'
      ]
    },
    {
      year: 2018,
      content: [
        'EAMS for Vietnam National University Hanoi',
        'Development eXCampus with tomato System Co., Ltd.',
        'eLearning: Vietnam Marine Univ - Crew Manning Center'
      ]
    }
  ],
  [
    {
      year: 2017,
      content: [
        'Outsourcing with IamZ Co., Ltd.',
        'Development eXCampus with tomato System Co., Ltd.',
        '07: BzCom Company Limited established'
      ]
    },
    {
      year: 2016,
      content: [
        'eLearning: HCM City, Dept. of Education',
        'eLearning: People’s security Univ in Hanoi',
        'eLearning: Bach Mai Hospital'
      ]
    },
    {
      year: '2014-2015',
      content: [
        'Started service for the SB (Search Bidding) Service',
        'eLearning: Agribank, Vietnam Airline, Da Nang City',
        '05: MMG Vietnam JSC Established'
      ]
    }
  ]
];

const About = () => {
  const newHistoryBz = [].concat(...historyBz);
  const [yearActive, setYearActive] = useState(2022);
  const [currentHistory, setCurrentHistory] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [hoverYear, setHoverYear] = useState();
  const slideContainerRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const showContent = (selectYear) => {
    setYearActive(selectYear);
    for (let index = 0; index < newHistoryBz.length; index++) {
      const data = newHistoryBz[index];
      if (selectYear === data?.year) {
        // setContent(data?.content);
      }
    }
  };

  const prevSlide = () => {
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? historyBz.length - 1 : prevIndex - 1
      );
    }, 300);
  };

  const nextSlide = () => {
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === historyBz.length - 1 ? 0 : prevIndex + 1
      );
    }, 300);
  };

  const handleScroll = (event) => {
    const container = slideContainerRef.current;
    if (!container) return;

    const slideHeight = container.clientHeight;
    const delta = event.deltaY;

    if (delta === 0) {
      return;
    } else if (delta > 50) {
      if (currentSlideIndex < newHistoryBz?.length - 1) {
        if (activeIndex < 3) {
          setActiveIndex(activeIndex + 1);
        }
        setCurrentSlideIndex(
          (prevIndex) => (prevIndex + 1) % newHistoryBz.length
        );
      }
    } else {
      if (currentSlideIndex > 0) {
        if (activeIndex > 1) {
          setActiveIndex(activeIndex - 1);
        }
        setCurrentSlideIndex(
          (prevIndex) =>
            (prevIndex - 1 + newHistoryBz.length) % newHistoryBz.length
        );
      }
    }

    container?.scrollTo({
      top: (currentSlideIndex + (delta > 0 ? 1 : -1)) * slideHeight,
      behavior: 'smooth'
    });
  };

  function handleTouchStart(event) {
    const touch = event.touches[0];
    setTouchStartY(touch.clientY);
  }

  function handleTouchMove(event) {
    const touch = event.touches[0];
    setTouchEndY(touch.clientY);
  }

  function handleTouchEnd() {
    if (touchStartY && touchEndY) {
      const deltaY = touchEndY - touchStartY;

      if (deltaY === 0) {
        return;
      } else if (deltaY < 50) {
        if (currentSlideIndex < newHistoryBz?.length - 1) {
          if (activeIndex < 3) {
            setActiveIndex(activeIndex + 1);
          }
          setCurrentSlideIndex(
            (prevIndex) => (prevIndex + 1) % newHistoryBz.length
          );
        }
      } else {
        if (currentSlideIndex > 0) {
          if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
          }
          setCurrentSlideIndex(
            (prevIndex) =>
              (prevIndex - 1 + newHistoryBz.length) % newHistoryBz.length
          );
        }
      }
    }
    setTouchStartY(null);
    setTouchEndY(null);
  }

  useEffect(() => {
    const container = slideContainerRef?.current;
    if (!container) return;

    const slideHeight = container.clientHeight;
    const scrollPosition = currentSlideIndex * slideHeight;
    container.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    setCurrentHistory(newHistoryBz[currentSlideIndex]);
    setYearActive(newHistoryBz[currentSlideIndex]?.year);
  }, [currentSlideIndex]);

  useEffect(() => {
    const container = slideContainerRef?.current;
    if (!container) return;

    container.addEventListener('wheel', handleScroll);
    return () => {
      container.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  const goToSlide = (index) => {
    setCurrentSlideIndex(index);
  };

  return (
    <div className="about bg-[#F3F6FE]">
      {/* Intro */}
      <section className="custom-hidden container pt-14 md:pt-16 lg:pt-28">
        <div className="lg:grid-rows-2 lg:grid lg:grid-flow-col lg:gap-8">
          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#412B26]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold">
              Who we are
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg">
              BZCOM was founded in Hanoi in 2017. In BZCOM, we believe in
              “consensus - communication - consideration” philosophy. We always
              consent with our employees, partners, as well as clients as the
              work is being carried out. To avoid unneccesary misunderstanding,
              communication is maintained clear and indispensable at work.
              Finally, we treat our employees, partners, and clients with utmost
              consideration and respect.
            </p>
          </div>

          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#412B26]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold">
              What we do
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg">
              To keep it short, we consult and we develop. We have been working
              with various customers who come from all sorts of areas, and we do
              understand that IT is not an easy topic to deal with. We help our
              customers understanding what they want and what they need, then
              offer to turn their wishes into reality.
            </p>
          </div>

          <div className="row-span-3 hidden lg:block">
            <img
              className="rounded object-cover lg:h-full lg:w-full"
              src={AboutSecond}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* Background image */}
      <section className="custom-hidden mx-auto max-w-[375px] px-[1rem] pt-14 sm:max-w-none sm:px-0 md:pt-16 lg:pt-28">
        <img
          className="h-[364px] rounded-2xl object-cover sm:h-full sm:rounded-none"
          src={AboutFirst}
          alt=""
        />

        <div className="container mt-[-65px] text-2xl font-semibold lg:mt-[-112px] lg:text-5xl lg:font-bold">
          <p className="text-[#98DAFF] opacity-60">Our History</p>
          <p className="mt-[-12px] text-[#2081B7] opacity-80">Our History</p>
          <p className="mt-[-12px] text-white">Our History</p>
          <p className="mt-[-12px] text-[#3170A9] opacity-70">Our History</p>
          <p className="mt-[-12px] text-[#325F87] opacity-40">Our History</p>
        </div>
      </section>

      {/* History */}
      <section className="history container flex pt-14 md:pt-16 lg:hidden lg:pt-28">
        <div className="flex lg:grid lg:grid-cols-6 lg:gap-14">
          {/* Years */}
          <ul className="years flex h-[300px] w-[80px] flex-col items-center xs:mr-2 xs:scale-75 sm:mr-6 sm:scale-100 lg:col-span-2 lg:mr-0 lg:w-full lg:grid-cols-2 lg:grid-rows-14">
            {/* 2023 */}
            <li className="mr-6 hidden self-center justify-self-end lg:block">
              <h4
                onClick={() => showContent(2023)}
                onMouseEnter={() => setHoverYear(2023)}
                onMouseLeave={() => setHoverYear()}
                className={`${
                  yearActive === 2023
                    ? 'title-active'
                    : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                } ${
                  yearActive !== 2023 &&
                  hoverYear === 2023 &&
                  'title-active xl:scale-105 xl:transition-all'
                }  text-xl font-extrabold text-[#8490A1] xl:text-2xl`}
              >
                2023
              </h4>
            </li>
            <li className="z-10">
              {activeIndex === 1 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  className={`${
                    hoverYear === 2023 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="block h-[100%] w-2 justify-self-center bg-[#D6D6D6] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:-rotate-[10deg]  xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

            <li className="z-10 row-start-3 lg:justify-self-center">
              {activeIndex === 2 ? (
                <img
                  onClick={() => goToSlide(currentIndex + 1)}
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  className={`${
                    hoverYear === 2022 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="block h-[100%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.85] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg]"></li>

            <li className="z-10 row-start-5">
              {activeIndex === 3 ? (
                <img
                  className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                  src={HistoryAct}
                  alt=""
                />
              ) : (
                <img
                  className={`${
                    hoverYear === 2021 && 'img-default scale-105 transition-all'
                  } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                  src={History}
                  alt=""
                />
              )}
            </li>

            <li className="block h-[100%] w-2 justify-self-center bg-[#D6D6D6] opacity-70 md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4  lg:-rotate-[10deg] xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>
          </ul>

          {/* Content */}
          <div
            className="w-full overflow-y-auto xs:h-[400px] sm:h-[300px]"
            ref={slideContainerRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            {newHistoryBz?.map((slide, index) => {
              return (
                <div
                  key={index}
                  className={`content col-span-4 pb-8 xs:h-[400px] xs:px-0 sm:h-[300px] sm:px-2 sm:pb-0 md:px-8`}
                >
                  <p className="current-year mb-4 text-2xl font-extrabold md:text-4xl lg:hidden">
                    {slide?.year}
                  </p>

                  <div
                    className={`${
                      currentHistory?.year === 2023
                        ? 'xs:h-[100%] sm:h-[85%]'
                        : 'xs:h-[95%] sm:h-[80%]'
                    } overflow-hidden overflow-y-auto`}
                  >
                    {slide?.content?.map((data, k) => {
                      return (
                        <p
                          key={k}
                          className="mb-6 text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg"
                        >
                          {data}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div className="mx-20 hidden justify-center gap-8 pt-12 lg:flex lg:scale-75 xl:scale-95">
        <div className="w-[5%] pt-10">
          <button
            onClick={() => {
              if (currentIndex === 0) {
                return;
              }
              prevSlide();
            }}
          >
            {currentIndex === 0 ? (
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.0838 40.0001L39.0005 53.0001C39.6116 53.6112 39.9316 54.3757 39.9605 55.2935C39.9893 56.2112 39.6693 57.0023 39.0005 57.6668C38.3893 58.2779 37.6116 58.5835 36.6671 58.5835C35.7227 58.5835 34.9449 58.2779 34.3338 57.6668L19.0005 42.3335C18.6671 42.0001 18.4305 41.639 18.2905 41.2501C18.1505 40.8612 18.0827 40.4446 18.0871 40.0001C18.0916 39.5557 18.1616 39.139 18.2971 38.7501C18.4327 38.3612 18.6682 38.0001 19.0038 37.6668L34.3371 22.3335C34.9482 21.7223 35.7127 21.4035 36.6305 21.3768C37.5482 21.3501 38.3393 21.669 39.0038 22.3335C39.6149 22.9446 39.9205 23.7223 39.9205 24.6668C39.9205 25.6112 39.6149 26.389 39.0038 27.0001L26.0838 40.0001ZM48.0838 40.0001L61.0005 53.0001C61.6116 53.6112 61.9316 54.3757 61.9604 55.2935C61.9893 56.2112 61.6693 57.0023 61.0005 57.6668C60.3893 58.2779 59.6116 58.5835 58.6671 58.5835C57.7227 58.5835 56.9449 58.2779 56.3338 57.6668L41.0005 42.3335C40.6671 42.0001 40.4305 41.639 40.2905 41.2501C40.1505 40.8612 40.0827 40.4446 40.0871 40.0001C40.0916 39.5557 40.1616 39.139 40.2971 38.7501C40.4327 38.3612 40.6682 38.0001 41.0038 37.6668L56.3371 22.3335C56.9482 21.7223 57.7127 21.4035 58.6305 21.3768C59.5482 21.3501 60.3393 21.669 61.0038 22.3335C61.6149 22.9446 61.9205 23.7223 61.9205 24.6668C61.9205 25.6112 61.6149 26.389 61.0038 27.0001L48.0838 40.0001Z"
                  fill="#CCCCCC"
                />
              </svg>
            ) : (
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.0838 40.7374L39.0005 53.7374C39.6116 54.3485 39.9316 55.113 39.9605 56.0308C39.9893 56.9485 39.6693 57.7396 39.0005 58.4041C38.3893 59.0152 37.6116 59.3208 36.6671 59.3208C35.7227 59.3208 34.9449 59.0152 34.3338 58.4041L19.0005 43.0708C18.6671 42.7374 18.4305 42.3763 18.2905 41.9874C18.1505 41.5985 18.0827 41.1819 18.0871 40.7374C18.0916 40.293 18.1616 39.8763 18.2971 39.4874C18.4327 39.0985 18.6682 38.7374 19.0038 38.4041L34.3371 23.0708C34.9482 22.4596 35.7127 22.1408 36.6305 22.1141C37.5482 22.0874 38.3393 22.4063 39.0038 23.0708C39.6149 23.6819 39.9205 24.4596 39.9205 25.4041C39.9205 26.3485 39.6149 27.1263 39.0038 27.7374L26.0838 40.7374ZM48.0838 40.7374L61.0005 53.7374C61.6116 54.3485 61.9316 55.113 61.9604 56.0308C61.9893 56.9485 61.6693 57.7396 61.0005 58.4041C60.3893 59.0152 59.6116 59.3208 58.6671 59.3208C57.7227 59.3208 56.9449 59.0152 56.3338 58.4041L41.0005 43.0708C40.6671 42.7374 40.4305 42.3763 40.2905 41.9874C40.1505 41.5985 40.0827 41.1819 40.0871 40.7374C40.0916 40.293 40.1616 39.8763 40.2971 39.4874C40.4327 39.0985 40.6682 38.7374 41.0038 38.4041L56.3371 23.0708C56.9482 22.4596 57.7127 22.1408 58.6305 22.1141C59.5482 22.0874 60.3393 22.4063 61.0038 23.0708C61.6149 23.6819 61.9205 24.4596 61.9205 25.4041C61.9205 26.3485 61.6149 27.1263 61.0038 27.7374L48.0838 40.7374Z"
                  fill="url(#paint0_linear_3210_10547)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3210_10547"
                    x1="18.0869"
                    y1="40.337"
                    x2="65.2502"
                    y2="37.3767"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#255682" />
                    <stop offset="0.515625" stopColor="#2683B7" />
                    <stop offset="1" stopColor="#26B3AE" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </button>
        </div>

        <div
          className={`relative w-[1180px] min-w-[1180px] max-w-[1180px] overflow-hidden py-2`}
        >
          <div
            onClick={() => setCurrentIndex(2)}
            className={`absolute ${
              currentIndex === 2 && 'hidden'
            } -top-8 -right-4 z-20 scale-50 cursor-pointer`}
          >
            <div className="pl-4">
              <h3 className="mt-2 text-[28px] font-bold text-[#8490A1]">
                2014
              </h3>
            </div>
            <div className="flex  items-center justify-center gap-[28px] object-cover">
              <img
                className={`w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                src={History}
                alt=""
              />
            </div>
          </div>
          <div
            className="flex h-full transition-transform duration-500"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {historyBz.map((slide, index) => (
              <div
                key={index}
                className="flex h-full w-full flex-col justify-center gap-12 pl-1 text-white md:flex-row"
              >
                {slide?.map((items, k) => {
                  return (
                    <div className="" key={k}>
                      <div className="w-full">
                        <div className="w-full pl-4">
                          <h3 className="text-[28px] font-bold text-[#8490A1]">
                            {items.year}
                          </h3>
                        </div>
                        <div className="flex  items-center justify-center gap-[28px] object-cover">
                          <img
                            className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                            src={HistoryAct}
                            alt=""
                          />

                          {items?.year === 2021 || items?.year === 2018 ? (
                            <svg
                              width="252"
                              height="13"
                              viewBox="0 0 252 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.97852 6.86865H246.979"
                                stroke="url(#paint0_linear_3366_1963)"
                                strokeWidth="12"
                                strokeLinecap="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_3366_1963"
                                  x1="6.97851"
                                  y1="7.35845"
                                  x2="8.55588"
                                  y2="-12.7923"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#5C5C5C" />
                                  <stop offset="0.5" stopColor="#A9A9A9" />
                                  <stop offset="1" stopColor="#DCDCDC" />
                                </linearGradient>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              className={
                                items?.year !== '2014-2015' ? '' : 'opacity-0'
                              }
                              width="252"
                              height="13"
                              viewBox="0 0 252 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 6.86865H246"
                                stroke="url(#paint0_linear_3247_1891)"
                                strokeWidth="12"
                                strokeLinecap="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_3247_1891"
                                  x1="6"
                                  y1="7.35845"
                                  x2="7.57736"
                                  y2="-12.7923"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#255682" />
                                  <stop offset="0.515625" stopColor="#2683B7" />
                                  <stop offset="1" stopColor="#26B3AE" />
                                </linearGradient>
                              </defs>
                            </svg>
                          )}
                        </div>
                        <div className="pt-[51px]">
                          {items.content.map((content, index) => {
                            return (
                              <div
                                className="  list-disc pl-[30px]"
                                key={index}
                              >
                                <ul className="list-disc">
                                  <li className="  pt-[10px] text-[16px] font-bold text-black">
                                    {content}
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>

        <div className="w-[5%] pt-10">
          <button
            onClick={() => {
              if (currentIndex === 2) {
                return;
              }
              nextSlide();
            }}
          >
            {currentIndex === 2 ? (
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.9165 40.4747L18.9999 27.4747C18.3888 26.8636 18.0688 26.1002 18.0399 25.1847C18.011 24.2691 18.331 23.4769 18.9999 22.808C19.611 22.1969 20.3888 21.8914 21.3332 21.8914C22.2777 21.8914 23.0554 22.1969 23.6665 22.808L38.9999 38.1414C39.3332 38.4747 39.5699 38.8358 39.7099 39.2247C39.8499 39.6136 39.9188 40.0302 39.9165 40.4747C39.9143 40.9191 39.8454 41.3358 39.7099 41.7247C39.5743 42.1136 39.3377 42.4747 38.9999 42.808L23.6665 58.1414C23.0554 58.7525 22.2921 59.0725 21.3765 59.1014C20.461 59.1302 19.6688 58.8102 18.9999 58.1414C18.3888 57.5302 18.0832 56.7525 18.0832 55.808C18.0832 54.8636 18.3888 54.0858 18.9999 53.4747L31.9165 40.4747ZM53.9165 40.4747L40.9999 27.4747C40.3888 26.8636 40.0688 26.1002 40.0399 25.1847C40.011 24.2691 40.331 23.4769 40.9999 22.808C41.611 22.1969 42.3888 21.8914 43.3332 21.8914C44.2777 21.8914 45.0554 22.1969 45.6665 22.808L60.9999 38.1414C61.3332 38.4747 61.5699 38.8358 61.7099 39.2247C61.8499 39.6136 61.9188 40.0302 61.9165 40.4747C61.9143 40.9191 61.8454 41.3358 61.7099 41.7247C61.5743 42.1136 61.3377 42.4747 60.9999 42.808L45.6665 58.1414C45.0554 58.7525 44.2921 59.0725 43.3765 59.1014C42.461 59.1302 41.6688 58.8102 40.9999 58.1414C40.3888 57.5302 40.0832 56.7525 40.0832 55.808C40.0832 54.8636 40.3888 54.0858 40.9999 53.4747L53.9165 40.4747Z"
                  fill="#CCCCCC"
                />
              </svg>
            ) : (
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.9165 40.7374L18.9999 27.7374C18.3888 27.1263 18.0688 26.3629 18.0399 25.4474C18.011 24.5318 18.331 23.7396 18.9999 23.0707C19.611 22.4596 20.3888 22.1541 21.3332 22.1541C22.2777 22.1541 23.0554 22.4596 23.6665 23.0707L38.9999 38.4041C39.3332 38.7374 39.5699 39.0985 39.7099 39.4874C39.8499 39.8763 39.9188 40.2929 39.9165 40.7374C39.9143 41.1818 39.8454 41.5985 39.7099 41.9874C39.5743 42.3763 39.3377 42.7374 38.9999 43.0707L23.6665 58.404C23.0554 59.0152 22.2921 59.3352 21.3765 59.364C20.461 59.3929 19.6688 59.0729 18.9999 58.404C18.3888 57.7929 18.0832 57.0152 18.0832 56.0707C18.0832 55.1263 18.3888 54.3485 18.9999 53.7374L31.9165 40.7374ZM53.9165 40.7374L40.9999 27.7374C40.3888 27.1263 40.0688 26.3629 40.0399 25.4474C40.011 24.5318 40.331 23.7396 40.9999 23.0707C41.611 22.4596 42.3888 22.1541 43.3332 22.1541C44.2777 22.1541 45.0554 22.4596 45.6665 23.0707L60.9999 38.4041C61.3332 38.7374 61.5699 39.0985 61.7099 39.4874C61.8499 39.8763 61.9188 40.2929 61.9165 40.7374C61.9143 41.1818 61.8454 41.5985 61.7099 41.9874C61.5743 42.3763 61.3377 42.7374 60.9999 43.0707L45.6665 58.404C45.0554 59.0152 44.2921 59.3352 43.3765 59.364C42.461 59.3929 41.6688 59.0729 40.9999 58.404C40.3888 57.7929 40.0832 57.0152 40.0832 56.0707C40.0832 55.1263 40.3888 54.3485 40.9999 53.7374L53.9165 40.7374Z"
                  fill="url(#paint0_linear_3210_10551)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3210_10551"
                    x1="18.0381"
                    y1="40.3802"
                    x2="65.2048"
                    y2="37.4198"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#255682" />
                    <stop offset="0.515625" stopColor="#2683B7" />
                    <stop offset="1" stopColor="#26B3AE" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Client */}
      <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default About;
