import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import ArrowLeft from '../../assets/icons/arrow-left.svg';
import SolutionFirst from '../../assets/images/solution-1.png';
import SolutionSecond from '../../assets/images/solution-2.png';
import SolutionThird from '../../assets/images/solution-3.png';
import SolutionFourth from '../../assets/images/solution-4.png';
import SolutionFive from '../../assets/images/solution-5.png';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="next-arrow right-[-50px] xl:transition-all"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="prev-arrow left-[-50px] xl:transition-all"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};

const SolutionSlider = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    autoplay: true,
    focusOnSelect: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          centerMode: false,
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      <div className="px-1 xs:px-2">
        <div className="solution__slider-item">
          <img className="item-img rounded-2xl" src={SolutionFirst} alt="" />
          <div className="item-overlay rounded-2xl">
            <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
              BzWare
            </h4>
          </div>
        </div>
      </div>

      <div className="px-1 xs:px-2">
        <div className="solution__slider-item">
          <img className="item-img rounded-2xl" src={SolutionSecond} alt="" />
          <div className="item-overlay rounded-2xl">
            <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
              Noti U
            </h4>
          </div>
        </div>
      </div>

      <div className="px-1 xs:px-2">
        <div className="solution__slider-item">
          <img className="item-img rounded-2xl" src={SolutionThird} alt="" />
          <div className="item-overlay rounded-2xl">
            <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
              BzMap
            </h4>
          </div>
        </div>
      </div>

      <div className="px-1 xs:px-2">
        <div className="solution__slider-item">
          <img className="item-img rounded-2xl" src={SolutionFourth} alt="" />
          <div className="item-overlay rounded-2xl">
            <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
              VeKia
            </h4>
          </div>
        </div>
      </div>

      <div className="px-1 xs:px-2">
        <div className="solution__slider-item">
          <img className="item-img rounded-2xl" src={SolutionFive} alt="" />
          <div className="item-overlay rounded-2xl">
            <h4 className="item-title text-lg font-medium text-white xs:text-xl md:text-2xl">
              ABIS
            </h4>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default SolutionSlider;
