import ClientSlider from '../../components/home/ClientSlider';
import SolutionSliderSyncing from '../../components/solution/SolutionSliderSyncing';

import SolutionBgr from '../../assets/images/solution-bgr.svg';

const Solution = () => {
  return (
    <div className="solution bg-[#F3F6FE]">
      <section className="custom-hidden container pt-14 md:pt-16 lg:pt-28">
        <div className="w-full lg:w-[40%]">
          <h3 className="mb-4 text-xl font-medium text-[#412B26] xs:text-2xl md:text-3xl">
            Our Comprehensive Solution
          </h3>
          <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-lg">
            With its flexibility, customization options, user-friendly
            interface, scalability, and dedicated support, our solution is the
            ideal choice for businesses of all sizes and industries.
          </p>
        </div>
      </section>

      <section className="custom-hidden relative h-[480px] pt-14 sm:h-[748px] md:pt-16 lg:h-[920px] lg:pt-28">
        <div className="container absolute top-[20%] left-[50%] z-30 translate-x-[-50%] lg:top-[24%] 2xl:h-[80%] 2xl:w-[70%]">
          <SolutionSliderSyncing />
        </div>

        <div className="flex h-full w-full">
          <div className="h-full w-[70%] bg-[#7D9DDB]"></div>
          <div className="h-full w-[30%] bg-[#DDE9FF]"></div>
        </div>

        <img
          className="absolute top-[-28%] right-0 blur-[1px]"
          src={SolutionBgr}
          alt=""
        />
      </section>

      <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default Solution;
