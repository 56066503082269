import { Component } from 'react';
import Slider from 'react-slick';

import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ItemImgFirst from '../../assets/images/solution-2-1.png';
import ItemImgSecond from '../../assets/images/solution-2-2.png';
import ItemImgThird from '../../assets/images/solution-2-3.png';
import ItemImgForth from '../../assets/images/solution-2-4.png';
import ItemImgFifth from '../../assets/images/solution-2-5.png';

const styleSliderFirst = {
  className: 'mb-8',
  arrows: false
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="next-arrow right-[-10px] z-50 lg:right-[50px]"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="prev-arrow left-[-10px] z-50 lg:left-[50px]"
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="" />
    </div>
  );
};
const styleSliderSecond = {
  className: 'slider_second-custom',
  centerMode: true,
  slidesToShow: 3,
  focusOnSelect: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

export default class SolutionSliderSyncing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <>
        <Slider
          {...styleSliderFirst}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[350px] lg:h-[450px] lg:rounded-2xl"
              src={ItemImgFirst}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[350px] lg:h-[450px] lg:rounded-2xl"
              src={ItemImgSecond}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[350px] lg:h-[450px] lg:rounded-2xl"
              src={ItemImgThird}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[350px] lg:h-[450px] lg:rounded-2xl"
              src={ItemImgForth}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[350px] lg:h-[450px] lg:rounded-2xl"
              src={ItemImgFifth}
              alt=""
            />
          </div>
        </Slider>

        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          {...styleSliderSecond}
        >
          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[80px] lg:h-[100px] lg:rounded-2xl"
              src={ItemImgFirst}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[80px] lg:h-[100px] lg:rounded-2xl"
              src={ItemImgSecond}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[80px] lg:h-[100px] lg:rounded-2xl"
              src={ItemImgThird}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[80px] lg:h-[100px] lg:rounded-2xl"
              src={ItemImgForth}
              alt=""
            />
          </div>

          <div className="p-1 sm:p-2 md:p-0">
            <img
              className="m-auto rounded-md object-cover md:h-[80px] lg:h-[100px] lg:rounded-2xl"
              src={ItemImgFifth}
              alt=""
            />
          </div>
        </Slider>
      </>
    );
  }
}
