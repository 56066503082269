import { Link } from 'react-router-dom';

import Facebook from '../../assets/icons/facebook.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Landline from '../../assets/icons/landline.svg';
import Location from '../../assets/icons/location.svg';
import Phone from '../../assets/icons/phone.svg';

const Footer = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <footer className="pt-9 pb-2 lg:pt-[80px] lg:pb-[18px]">
      <div className="container mb-5 text-white sm:grid sm:grid-cols-2 sm:gap-4 md:grid-cols-3 lg:pb-16 xl:flex xl:justify-around">
        <div className="mb-5 lg:mb-0 lg:justify-self-center xl:w-[40%]">
          <Link
            className="text-xl font-bold xs:text-2xl sm:text-3xl md:text-4xl"
            to="/"
          >
            BZCOM
          </Link>
          <p className="mb-5 text-sm font-normal xs:text-base sm:mt-4 lg:text-lg">
            “Consensus - Communication - Consideration”
          </p>

          <ul className="flex items-center justify-start">
            <li className="mr-4">
              <Link
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.facebook.com/BZCOM.Co.Ltd"
              >
                <img
                  className="h-8 w-8 xl:transition-all xl:hover:scale-110"
                  src={Facebook}
                  alt=""
                />
              </Link>
            </li>
            <li className="mr-4">
              <Link
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                to="https://instagram.com/bzcomvn?igshid=YmMyMTA2M2Y="
              >
                <img
                  className="h-8 w-8 xl:transition-all xl:hover:scale-110"
                  src={Instagram}
                  alt=""
                />
              </Link>
            </li>
          </ul>
        </div>

        <ul className="mb-5 text-sm font-normal xs:text-base sm:grid sm:grid-cols-2 sm:gap-4 sm:self-end sm:justify-self-end md:mb-0 lg:justify-self-center lg:text-lg">
          <li className="mb-4">
            <Link className="link relative cursor-pointer" to="/about-us">
              About us
            </Link>
          </li>

          <li className="mb-4">
            <Link className="link relative cursor-pointer" to="/service">
              Services
            </Link>
          </li>

          <li className="mb-4">
            <Link className="link relative cursor-pointer" to="/solution">
              Solutions
            </Link>
          </li>

          <li>
            <Link className="link relative cursor-pointer" to="/contact">
              Contact
            </Link>
          </li>
        </ul>

        <ul className="text-sm font-normal xs:text-base md:self-end md:justify-self-end lg:justify-self-center lg:text-lg">
          <li>
            <Link
              className="flex cursor-pointer items-center"
              target="_blank"
              rel="noopener noreferrer"
              to="https://goo.gl/maps/3ZAPjhQbVNDRdMCG6"
            >
              <img className="mr-2" src={Location} alt="" />
              <span className="link relative ">Come with us to the map</span>
            </Link>
          </li>

          <li className="my-[16px]">
            <Link
              className="flex cursor-pointer items-center"
              to="tel:+84 2432 004 887"
            >
              <img className="mr-2" src={Phone} alt="" />
              <span className="link relative ">+84 (2432) 004 887</span>
            </Link>
          </li>

          <li className="my-[16px]">
            <Link
              className="flex cursor-pointer items-center"
              to="tel:+84 983 104 170"
            >
              <img className="mr-2" src={Landline} alt="" />
              <span className="link relative ">+84 (983) 104 170</span>
            </Link>
          </li>

          {/* <ul className="flex items-center justify-start">
            <li className="mr-6">
              <Link
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                to="https://instagram.com/bzcomvn?igshid=YmMyMTA2M2Y="
              >
                <img className="h-8 w-8" src={Instagram} alt="" />
              </Link>
            </li>
            <li className="mr-6">
              <Link
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.facebook.com/BZCOM.Co.Ltd"
              >
                <img className="h-8 w-8" src={Facebook} alt="" />
              </Link>
            </li>
          </ul> */}
        </ul>
      </div>

      <div className="text-center">
        <span className="text-sm font-light text-white xs:text-base">
          {/* {new Date().getFullYear()} */}
          &copy; 2023 by Bzcom Co., Ltd. All rights Reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
