import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Agribank from '../../assets/images/agribank.svg';
import DaNangFantastiCity from '../../assets/images/da-nang-fantasti-city.svg';
import University from '../../assets/images/university.svg';
import Vfa from '../../assets/images/vfa.svg';
import VietNamAirlines from '../../assets/images/viet-nam-airlines.svg';

const ClientSlider = (props) => {
  const settings = {
    infinite: props?.infinite,
    autoplay: props?.autoplay,
    focusOnSelect: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: props?.autoplayLg,
          speed: 500,
          autoplaySpeed: 2000,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          centerMode: false,
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      <img className={props?.classImage} src={VietNamAirlines} alt="" />

      <img className={props?.classImage} src={DaNangFantastiCity} alt="" />

      <img className={props?.classImage} src={Vfa} alt="" />

      <img className={props?.classImage} src={University} alt="" />

      <img className={props?.classImage} src={Agribank} alt="" />
    </Slider>
  );
};

export default ClientSlider;
