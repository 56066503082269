import ClientSlider from '../../components/home/ClientSlider';

import ServiceFirst from '../../assets/images/service-1.png';
import ServiceSecond from '../../assets/images/service-2.png';
import ServiceThird from '../../assets/images/service-3.png';

import UnsplashFirst from '../../assets/images/unsplash-1.svg';
import UnsplashSecond from '../../assets/images/unsplash-2.svg';
import UnsplashThird from '../../assets/images/unsplash-3.svg';

const Service = () => {
  return (
    <div className="service bg-[#F3F6FE]">
      <section className="custom-hidden container pt-14 xl:pt-0">
        <div className="grid grid-cols-1 md:grid-cols-3 md:items-center md:justify-center">
          <div className="col-span-1 flex">
            <img
              className="h-[80%] rounded-lg object-cover xl:mt-[-10%] xl:h-full"
              src={ServiceFirst}
              alt=""
            />
            <img
              className="relative top-[40%] left-[-70%] h-[80%] rounded-lg border-[16px] border-[#F3F6FE] object-cover xs:left-[-60%] xs:top-[30%] md:left-[-55%] md:mt-[60%] xl:mt-[30%] xl:h-full"
              src={ServiceSecond}
              alt=""
            />
          </div>

          <div></div>

          <div className="col-span-1 pt-[40%] xs:pt-[30%] md:pt-0">
            <h3 className="mb-4 text-xl font-medium text-[#412B26] xs:text-2xl md:text-3xl">
              BZCOM COMPANY LIMITED SINCE 2017
            </h3>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base md:text-lg lg:text-lg">
              Leading the 4th industrial revolution and developing various
              software solutions/services, It is a value partner that enhances
              customers’ business competitiveness and realizes business
              innovation. BzCom intends to fulfill its social responsibility by
              combining various businesses, which are the core competitiveness
              of modern society.
            </p>
          </div>
        </div>

        <div></div>
      </section>

      <section className="custom-hidden container overflow-hidden pt-14 md:pt-16 lg:pt-28">
        <ul className="grid grid-flow-col gap-8 overflow-x-auto pb-2 sm:grid-cols-3 sm:gap-3 md:gap-4 lg:gap-6 xl:gap-10 2xl:gap-x-16">
          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashFirst}
              alt=""
            />

            <h4 className="pb-6 text-lg font-medium text-[#412B26] xs:text-xl md:text-2xl">
              IT Consultation
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              Through our experiences and knowledge in the field, we are here to
              help you find your solution quickly and easily.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashSecond}
              alt=""
            />
            <h4 className="mb-6 text-lg font-medium text-[#412B26] xs:text-xl md:text-2xl">
              IT Outsource
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              We offer outsourcing service for our clients. We can save you not
              only resources, time, but money also.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashThird}
              alt=""
            />
            <h4 className="mb-6  text-lg font-medium text-[#5D4A46] xs:text-xl md:text-2xl">
              IT Solutions
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              If your problems are troubling you, our solutions might be the
              answer you are looking for.
            </p>
          </li>
        </ul>
      </section>

      <section className="custom-hidden container pt-14 md:pt-16 lg:pt-28">
        <ul className="service-list grid grid-cols-1 md:grid-cols-2">
          <li className="service__list-content flex h-[300px] flex-col items-center justify-center text-white md:h-[400px]">
            <h4 className="mb-4 text-lg font-medium xs:text-xl md:text-2xl">
              IT Consultation
            </h4>
            <p className="px-4 text-justify text-sm font-normal xs:text-base md:px-14 lg:text-lg">
              Through our experiences and knowledge in the field, there is no
              question that is too hard to answer and no problem too hard to
              solve. We are here to help you find your solution quickly and
              easily. Our members are ready to: (1) provide advice or service on
              how best we can execute your requirements at a given time and (2)
              ensure the solution is compliance with applicable laws or
              regulations, if any.
            </p>
          </li>

          <li className="h-[300px] md:h-[400px]">
            <img
              className="h-full w-full object-cover opacity-80 hue-rotate-[-15deg]"
              src={ServiceThird}
              alt=""
            />
          </li>

          <li className="h-[300px] md:h-[400px]">
            <img
              className="h-full w-full object-cover opacity-80 hue-rotate-[-15deg]"
              src={ServiceThird}
              alt=""
            />
          </li>

          <li className="service__list-content row-start-3 flex h-[300px] flex-col items-center justify-center text-white md:h-[400px]">
            <h4 className="mb-4 text-lg font-medium xs:text-xl md:text-2xl">
              IT Outsource
            </h4>
            <p className="px-4 text-justify text-sm font-normal xs:text-base md:px-14 lg:text-lg">
              We offer outsourcing service for our clients. We can save you not
              only resources, time, but money also. From creating a software
              package to an entirely new application on your own servers using
              Git or Mercurial, we make it simple and efficient so that even the
              most demanding projects are easier than ever before in terms of
              developing tools and applications from scratch.
            </p>
          </li>

          <li className="service__list-content flex h-[300px] flex-col items-center justify-center text-white md:h-[400px]">
            <h4 className="mb-4 text-lg font-medium xs:text-xl md:text-2xl">
              IT Solutions
            </h4>
            <p className="px-4 text-justify text-sm font-normal xs:text-base md:px-14 lg:text-lg">
              If your problems are troubling you, our solutions might be the
              answer you are looking for. There is virtually no one size fit all
              solution, but we can customize ours to fit what you need. Our
              knowledgeable support staff will help with every aspect of your
              issue and get you through any issues that come up.
            </p>
          </li>

          <li className="h-[300px] md:h-[400px]">
            <img
              className="h-full w-full object-cover opacity-80 hue-rotate-[-15deg]"
              src={ServiceThird}
              alt=""
            />
          </li>
        </ul>
      </section>

      <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default Service;
