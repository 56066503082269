import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Close from '../../assets/icons/close.svg';
import Menu from '../../assets/icons/menu.svg';
import Logo from '../../assets/images/logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const lock = () => document.body.classList.add('lock');
  const unLock = () => document.body.classList.remove('lock');

  const openNav = () => {
    setIsClose(false);
    setIsOpen(true);
    lock();
  };
  const closeNav = () => {
    setIsOpen(false);
    setIsClose(true);
    unLock();
  };

  return (
    <header className="container">
      <div className="hidden  py-[16px] text-base font-medium lg:flex lg:items-center lg:justify-end">
        <Link
          className="text-sm text-[#E5E7EB] transition duration-150 hover:text-white"
          to="tel:+84 2432 004 887"
        >
          +84 (2432) 004 887
        </Link>

        <i className="mx-4 h-5 border-r-2 border-[#E5E7EB]"></i>

        <Link
          className="text-sm text-[#E5E7EB] transition duration-150 hover:text-white"
          to="tel:+84 983 104 170"
        >
          +84 (983) 104 170
        </Link>

        <i className="mx-4 h-5 border-r-2 border-[#E5E7EB]"></i>

        <Link
          className="text-[#E5E7EB] transition duration-150 hover:text-white"
          to="mailto: admin@bzcom.vn"
        >
          admin@bzcom.vn
        </Link>
      </div>

      <div className="nav flex items-center justify-between pt-[32px] lg:pt-0">
        <Link to="/">
          <img
            className="h-[40px] cursor-pointer object-cover md:h-[44px] lg:h-[48px] xl:h-[52px]"
            src={Logo}
            alt=""
          />
        </Link>

        <nav className="hidden lg:block">
          <ul className="flex h-full w-full items-center text-lg font-normal text-[#F9FAFB]">
            <li className="mr-[40px]">
              <NavLink to="/about-us" className="link relative cursor-pointer">
                About us
              </NavLink>
            </li>
            <li className="mr-[40px]">
              <NavLink to="/service" className="link relative cursor-pointer">
                Service
              </NavLink>
            </li>
            <li className="mr-[40px]">
              <NavLink to="/solution" className="link relative cursor-pointer">
                Solutions
              </NavLink>
            </li>
            <li>
              <NavLink
                className="header-btn inline-flex cursor-pointer items-center justify-center px-6 font-medium text-[#333] transition duration-300 hover:bg-slate-300"
                to="/contact"
              >
                Contact us
                <i className="header__btn-dots ml-2">
                  <span className="m-1"></span>
                </i>
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Tablet - Mobile */}
        <div className="lg:hidden">
          <img
            className="h-[28px] w-[28px]"
            onClick={() => openNav()}
            src={Menu}
            alt=""
          />

          <div className="nav__mobile">
            <nav
              className={`${isOpen && 'nav__mobile-in'} ${
                isClose && 'nav__mobile-out'
              } fixed right-[-100%] z-10 flex w-full flex-col bg-white px-[16px] py-[32px] xs:w-[80%]`}
            >
              <img
                onClick={() => closeNav()}
                className="w-10 self-end"
                src={Close}
                alt=""
              />

              <ul className="self-center text-center text-base font-medium text-[#A0A0A0]">
                <li className="my-[32px]">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                    end
                  >
                    Home
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    About us
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/service"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Service
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/solution"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Solutions
                  </NavLink>
                </li>

                <li className="mb-[32px]">
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>

            <div
              onClick={() => closeNav()}
              className={`${isOpen && 'overlay-in'} ${
                isClose && 'overlay-out'
              } fixed right-[-100%] bg-black opacity-30`}
            ></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
