import Slider from 'react-slick';
import Slogan from './Slogan';

import ProductXl from '../../assets/images/product-xl.png';

const settings = {
  className: 'banner-slider',
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 9000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Banner = () => {
  return (
    <section className="banner overflow-hidden">
      <Slider {...settings}>
        <div className="custom-hidden slider-first">
          <div className="custom-hidden container flex flex-col items-center py-9 md:flex-row md:items-center md:justify-around">
            <img
              src={ProductXl}
              alt=""
              className="w-[304px] object-cover md:w-[374px] lg:w-[494px]"
            />
            <div className="mt-9 text-center text-white md:w-[45%] md:text-left">
              <div className="mb-5 h-10">
                <Slogan />
              </div>

              <h1 className="text-xl font-bold xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                We are your partner
              </h1>
            </div>
          </div>
        </div>

        <div className="custom-hidden">
          <div className="custom-hidden container flex flex-col items-center py-9 md:flex-row md:items-center md:justify-around">
            <img
              src={ProductXl}
              alt=""
              className="w-[304px] object-cover md:w-[374px] lg:w-[494px]"
            />
            <div className="mt-9 text-center text-white md:w-[38%] md:text-left">
              <h1 className="mb-4 text-xl font-bold xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                BzWare Solution
              </h1>
              <p className="text-left text-sm font-normal tracking-wide xs:text-center xs:text-base md:text-left lg:text-lg">
                When you need a scalable organization tool, we recommend that
                you go with our solution, BZWARE, which can help make sure every
                single one of your employees will get everything they are going
                through in order to accomplish their goals.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Banner;
